import { useEffect, useRef, useState } from "react";
import { useManyways } from "../../context/ManywaysContext";
import SingleNode from "../SingleNode/SingleNode";
import { slugify } from "../../lib/utils";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ManywaysFooter from "../Footer/ManywaysFooter";
import { IoCloseOutline } from "react-icons/io5";

const NodeRenderer = (props) => {
  const { nodes, currentNodeId, mode, treeConfig, classNamePrefix, slug } =
    useManyways();
  const [transitioningNodes, setTransitioningNodes] = useState([]);
  const lastNodeRef = useRef(null);

  // Add transitioning state to the last two nodes
  // useEffect(() => {
  //   if (nodes && nodes.length > 1) {
  //     setTransitioningNodes(nodes.map((_, index) => index >= nodes.length - 2));
  //   }

  //   const timeout = setTimeout(() => {
  //     setTransitioningNodes(nodes.map(() => false));
  //   }, 2000);

  //   return () => clearTimeout(timeout);
  // }, [nodes]);

  // Scroll to the bottom of the current node when nodes change
  useEffect(() => {
    if (mode === "scroll" && nodes.length > 1 && lastNodeRef.current) {
      window.scrollTo({
        top: lastNodeRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  }, [lastNodeRef.current]);

  return (
    <div
      style={treeConfig?.run_mode?.theme_vars}
      className={`${classNamePrefix}-${slug} ${classNamePrefix}-${mode} ${classNamePrefix}-journey-container has-header-${!!treeConfig
        ?.run_mode?.logo} ${nodes
        .map((n) => `mw-${slugify(n.title)}`)
        .join(" ")}`}
    >
      {/* Displays when in scroll mode with a global background set */}
      {mode === "scroll" && treeConfig?.run_mode?.background_image?.url && (
        <div
          className={`${classNamePrefix}-global-bg-image`}
          style={{
            backgroundImage: `url(${treeConfig?.run_mode?.background_image?.url})`,
          }}
        ></div>
      )}
      {/* Adds a header when a logo is added */}
      {(treeConfig?.run_mode?.logo?.url || treeConfig?.run_mode?.header) && (
        <Header />
      )}

      {nodes
        .filter((n) => mode === "scroll" || n.id === currentNodeId)
        .map((currentNode, idx) => (
          <SingleNode
            key={currentNode.id}
            currentNode={currentNode}
            // transitioningNodes={transitioningNodes}
            isFirstNode={idx === 0}
            ref={idx === nodes.length - 1 ? lastNodeRef : null}
          />
        ))}
      {/* Is the journey in a modal? */}

      {treeConfig?.run_mode?.interaction_type === "modal" && (
        <button
          className={`${classNamePrefix}-close-journey-button`}
          onClick={() => window.manyways.dispatcher.publish("journey/close")}
        >
          <IoCloseOutline />
          <span>{treeConfig?.run_mode?.custom_close_text || ""}</span>
        </button>
      )}
      <Footer />
    </div>
  );
};

export default NodeRenderer;
