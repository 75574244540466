import { useState } from "react";
import { useManyways } from "../../context/ManywaysContext";
import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";

const CommentLogin = () => {
  const { login, setUser } = useManyways();
  const [formData, setFormData] = useState({ email: "", password: "" });

  const handleLogin = async ({ formData }) => {
    const { email, password } = formData;

    const _user = await login({ email, password }, setUser);
    if (_user) {
      // Login  successful
      console.log("Logged in user:", _user);
    } else {
      // Login failed
      console.error("Failed to log in");
    }
  };

  const schema = {
    type: "object",
    required: ["email", "password"],
    properties: {
      email: { type: "string", title: "Email" },
      password: { type: "string", title: "Password" },
    },
  };

  const uiSchema = {
    email: {
      "ui:widget": "email",
    },
    password: {
      "ui:widget": "password",
    },
  };

  return (
    <div className="comment-login">
      <Form
        schema={schema}
        uiSchema={uiSchema}
        formData={formData}
        validator={validator}
        onChange={({ formData }) => setFormData(formData)}
        onSubmit={handleLogin}
        onError={(errors) => console.log("Form errors", errors)}
      >
        <div className="form-actions">
          <button type="submit" className="submit-button">Submit</button>
        </div>
      </Form>
    </div>
  );
};

export default CommentLogin;
