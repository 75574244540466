import BooleanField from "./BooleanField/BooleanField";
import CustomFieldExample from "./CustomFieldExample/CustomFieldExample";
import ImageSingle from "./ImageSingle/ImageSingle";
import MediaContent from "./MediaContent/MediaContent";
import Slideshow from "./Slideshow/Slideshow";
import StringField from "./StringField/StringField";
import TabbedContent from "./TabbedContent/TabbedContent";
import PDFResponseButton from "./PDFResponseButton/PDFResponseButton";
import ExcelSheetInput from "./ExcelSheetInput/ExcelSheetInput";
import InputPrompt from "./InputPrompt/InputPrompt";

const fields = {
  ExcelSheetInput: ExcelSheetInput,
  ImageSingle: ImageSingle,
  MediaContent: MediaContent,
  TabbedContent: TabbedContent,
  Slideshow: Slideshow,
  StringField: StringField,
  CustomFieldExample: CustomFieldExample,
  BooleanField: BooleanField,
  PDFResponseButton: PDFResponseButton,
  InputPrompt: InputPrompt,
};

export default fields;
