import {
  getWidget,
  getUiOptions,
  optionsList,
  TranslatableString,
} from "@rjsf/utils";
import isObject from "lodash/isObject";
import { useManyways } from "../../../context/ManywaysContext";

/** The `BooleanField` component is used to render a field in the schema is boolean. It constructs `enumOptions` for the
 * two boolean values based on the various alternatives in the schema.
 *
 * @param props - The `FieldProps` for this template
 */
function BooleanField(props) {
  const {
    schema,
    name,
    uiSchema,
    idSchema,
    formData,
    registry,
    required,
    disabled,
    readonly,
    hideError,
    autofocus,
    title,
    onChange,
    onFocus,
    onBlur,
    rawErrors,
  } = props;
  const { classNamePrefix } = useManyways();
  const { title: schemaTitle } = schema;
  const { widgets, formContext, translateString, globalUiOptions } = registry;
  const {
    widget = "checkbox",
    title: uiTitle,
    label: displayLabel = true,
    ...options
  } = getUiOptions(uiSchema, globalUiOptions);
  const Widget = getWidget(schema, widget, widgets);
  const yes = translateString(TranslatableString.YesLabel);
  const no = translateString(TranslatableString.NoLabel);
  let enumOptions;
  const label = uiTitle ?? schemaTitle ?? title ?? name;
  if (Array.isArray(schema.oneOf)) {
    enumOptions = optionsList(
      {
        oneOf: schema.oneOf
          .map((option) => {
            if (isObject(option)) {
              return {
                ...option,
                title: option.title || (option.const === true ? yes : no),
              };
            }
            return undefined;
          })
          .filter((o) => o),
      },
      uiSchema
    );
  } else {
    const schemaWithEnumNames = schema;
    const enums = schema.enum ?? [true, false];
    if (
      !schemaWithEnumNames.enumNames &&
      enums.length === 2 &&
      enums.every((v) => typeof v === "boolean")
    ) {
      enumOptions = [
        {
          value: enums[0],
          label: enums[0] ? yes : no,
        },
        {
          value: enums[1],
          label: enums[1] ? yes : no,
        },
      ];
    } else {
      enumOptions = optionsList(
        {
          enum: enums,
          enumNames: schemaWithEnumNames.enumNames,
        },
        uiSchema
      );
    }
  }
  console.log("im a boolean");
  return (
    <div className={`${classNamePrefix}-boolean`}>
      <Widget
        options={{ ...options, enumOptions }}
        schema={schema}
        uiSchema={uiSchema}
        id={idSchema.$id}
        name={name}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        label={label}
        hideLabel={!displayLabel}
        value={formData}
        required={required}
        disabled={disabled}
        readonly={readonly}
        hideError={hideError}
        registry={registry}
        formContext={formContext}
        autofocus={autofocus}
        rawErrors={rawErrors}
      />
    </div>
  );
}

export default BooleanField;
