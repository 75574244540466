import { useState, useEffect } from "react";
import { useManyways } from "../../../context/ManywaysContext";

const InputPrompt = ({ schema, onChange, formData, ...props }) => {
  const { classNamePrefix } = useManyways();
  const [properties, setProperties] = useState({});
  const [inputValue, setInputValue] = useState(formData || "");

  useEffect(() => {
    try {
      const properties = JSON.parse(schema.text);
      setProperties(properties);
    } catch (e) {
      console.error("Error parsing InputPrompt schema:", e);
    }
  }, [schema]);

  const handlePromptClick = (prompt) => {
    // If there's existing text, add a line break before new prompt
    const prefix = inputValue ? inputValue + "\n" : "";
    const newValue = prefix + prompt + "\n";
    setInputValue(newValue);
    onChange(newValue);
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onChange(newValue);
  };

  return (
    <div
      className={`${classNamePrefix}-input-prompt ${schema?.customClassName || ""}`}
    >
      {properties.label && (
        <label className="prompt-label">{properties.label}</label>
      )}

      {properties.description && (
        <p className="prompt-description">{properties.description}</p>
      )}

      <div className="prompt-options">
        {properties.options?.map((option, index) => (
          <button
            key={index}
            onClick={() => handlePromptClick(option)}
            className="prompt-button"
            type="button"
          >
            {option}
          </button>
        ))}
      </div>

      <div className="prompt-input-container">
        <textarea
          value={inputValue}
          onChange={handleInputChange}
          className="prompt-input"
          placeholder="Your response..."
          rows={Math.max(4, (inputValue.match(/\n/g) || []).length + 1)}
          readOnly={schema.readonly}
        />
      </div>
    </div>
  );
};

export default InputPrompt;
