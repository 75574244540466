import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";

const SpreadsheetUploader = ({ onFileUpload, onError }) => {
  const [droppedFiles, setDroppedFiles] = useState(null);
  const [uploading, setUploading] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles?.length > 0) {
      setDroppedFiles(acceptedFiles);
    }
  }, []);

  const handleConfirm = async () => {
    if (droppedFiles) {
      setUploading(true);

      try {
        const formData = new FormData();

        // Append files to FormData as a single array
        droppedFiles.forEach((file) => {
          formData.append("files", file);
        });

        const response = await axios.post(
          "https://g6-portal.vercel.app/api/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        );

        console.log("Response:", response.data);
        if (response?.data?.urls?.length > 0) {
          onFileUpload(response.data?.urls);
        }
      } catch (error) {
        console.error("Upload error:", error);
        onError?.(error);
      } finally {
        setUploading(false);
      }
    }
  };

  const handleReset = () => {
    setDroppedFiles(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "text/csv": [".csv"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "application/vnd.ms-excel": [".xls"],
    },
    multiple: true,
  });

  if (droppedFiles) {
    return (
      <div className="spreadsheet-confirmation">
        <h4>Confirm your files</h4>
        <div className="file-list">
          {droppedFiles.map((file, index) => (
            <div key={index} className="file-item">
              <span className="file-name">{file.name}</span>
              <span className="file-size">
                ({(file.size / 1024).toFixed(1)} KB)
              </span>
            </div>
          ))}
        </div>
        <div className="confirmation-actions">
          <button
            onClick={handleReset}
            className="button-secondary"
            disabled={uploading}
          >
            Choose Different Files
          </button>
          <button
            onClick={handleConfirm}
            className="button-primary"
            disabled={uploading}
          >
            {uploading ? "Uploading..." : "Upload Files"}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div
      {...getRootProps()}
      className={`spreadsheet-dropzone ${isDragActive ? "drag-active" : ""}`}
    >
      <input {...getInputProps()} />
      <div className="dropzone-content">
        {isDragActive ? (
          <p>Drop your files here...</p>
        ) : (
          <>
            <p>Drag & drop your files here</p>
            <p className="dropzone-hint">
              or click to select files (.csv, .xlsx, .xls)
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default SpreadsheetUploader;
