const setupPublicMethods = () => {
  window.exports = window.exports || {};
  window.umami = window.umami || { track: function (x) {} };

  window.manyways = {};
  window.manyways.restart = function (e) {
    !!e && e.preventDefault();
    window.umami.track("restart");
    if (window.location.search.includes("continue") !== -1) {
      window.location.href = window.location.href.split("?")[0];
    } else {
      window.location.reload();
    }
  };

  window.manyways.dispatcher = (function () {
    var topics = {};
    var hOP = topics.hasOwnProperty;

    return {
      subscribe: function (topic, listener) {
        // Create the topic's object if not yet created
        if (!hOP.call(topics, topic)) topics[topic] = [];

        // Add the listener to queue
        var index = topics[topic].push(listener) - 1;

        // Provide handle back for removal of topic
        return {
          remove: function () {
            delete topics[topic][index];
          },
        };
      },
      publish: function (topic, info) {
        // If the topic doesn't exist, or there's no listeners in queue, just leave
        if (!hOP.call(topics, topic)) return;

        // Cycle through topics queue, fire!
        topics[topic].forEach(function (item) {
          item(info !== undefined ? info : {});
        });
      },
    };
  })();

  window.manyways.back = function (e) {
    window.manyways.dispatcher.publish("graph/back");
  };

  window.manyways.share = function (e) {
    if (e?.type === "click" || e?.keyCode === 13) {
      !!e && e.preventDefault();
      window.umami.track("Share");
      navigator.share({
        title: document.title,
        url: window.location.href,
      });
    }
  };

  window.manyways.closeJourney = function (e) {
    window.manyways.dispatcher.publish("journey/close");
  };
};

export default setupPublicMethods;
