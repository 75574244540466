import { useManyways } from "../../../context/ManywaysContext";

const ImageSingle = ({ schema, ...props }) => {
  const { classNamePrefix } = useManyways();
  const { image, width, height, display, caption } = schema;

  const styles = {
    width: "100%",
    maxWidth: width ? width : "100%",
    height: height ? height : "100%",
    objectFit: display ? display : "auto",
  };

  return (
    image && (
      <div className={`${classNamePrefix}-image-container`}>
        <img
          src={image?.url}
          alt={image?.alt_text ? image.alt_text : image?.name}
          style={styles}
        />
        {caption && <small>{caption}</small>}
      </div>
    )
  );
};

export default ImageSingle;
