const CustomFieldExample = ({ schema, ...props }) => {
  console.log(schema.text);
  return (
    <div className={`media-content ${schema?.customClassName || ""}`}>
      {/* <h3>This is an example of a custom component.</h3> */}
      {/* <div className="text-container">
        {schema?.text ? <pre>{schema.text}</pre> : <p>No content provided</p>}
      </div> */}
    </div>
  );
};

export default CustomFieldExample;
