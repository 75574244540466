import { merge } from "lodash";

const isFormWithOneChoiceFieldOnly = (formSchema, uiSchema) => {
  if (!!formSchema?.properties) {
    const properties = Object.keys(formSchema.properties).filter((key) => {
      return formSchema.properties[key].type !== "null" && key;
    });
    if (properties.length === 1) {
      const property = formSchema.properties[properties[0]];
      if (
        property.type === "string" &&
        property.enum &&
        uiSchema[properties[0]] &&
        uiSchema[properties[0]]["ui:widget"] === "radio"
      ) {
        return true;
      }
    }
  }
  return false;
};

const mergeNodetoLocaleNoSubNode = (node, locale) => {
  let _node = {
    ...node,
  };
  if (!!node?.translations?.[locale]) {
    let _langNode = {
      ...node?.translations?.[locale],
    };
    _node = merge(_node, _langNode);
  }
  return _node;
};

const slugify = (str) =>
  !!str
    ? str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, "")
        .replace(/[\s_-]+/g, "-")
        .replace(/^-+|-+$/g, "")
    : "";

function transformErrors(errors, translations, locale) {
  return errors.map((error) => {
    if (!translations || !translations[locale]) {
      return error;
    }
    if (!!translations[locale].errors?.[error.property]?.[error.name]) {
      error.message =
        translations[locale].errors?.[error.property]?.[error.name];
    }
    return error;
  });
}

const getNumberPrecision = (step) => {
  if (!step) return 0;
  const stepString = step.toString();
  const decimal = stepString.indexOf(".");
  if (decimal === -1) return 0;
  return stepString.length - decimal - 1;
};

export {
  isFormWithOneChoiceFieldOnly,
  mergeNodetoLocaleNoSubNode,
  slugify,
  transformErrors,
  getNumberPrecision,
};
