import { useCallback } from "react";
import {
  ariaDescribedByIds,
  enumOptionsDeselectValue,
  enumOptionsIsSelected,
  enumOptionsSelectValue,
  enumOptionsValueForIndex,
  optionId,
} from "@rjsf/utils";

const CheckboxWidget = ({
  schema,
  options,
  value,
  required,
  disabled,
  readonly,
  autofocus = false,
  onBlur,
  onFocus,
  onChange,
  id,
}) => {
  const { columns } = schema;
  const { enumOptions, enumDisabled, inline, emptyValue } = options;
  const checkboxesValues = Array.isArray(value) ? value : [value];

  const gridTemplateStyles = {
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
  };

  const handleBlur = useCallback(
    ({ target: { value } }) =>
      onBlur(id, enumOptionsValueForIndex(value, enumOptions, emptyValue)),
    [onBlur, id, enumOptions, emptyValue]
  );

  const handleFocus = useCallback(
    ({ target: { value } }) =>
      onFocus(id, enumOptionsValueForIndex(value, enumOptions, emptyValue)),
    [onFocus, id, enumOptions, emptyValue]
  );

  const hasImages = () => {
    if (!enumOptions) {
      return false;
    }
    return (
      enumOptions.find(
        (item) =>
          item && item.schema && item.schema.icon && item.schema.icon.url
      ) !== undefined
    );
  };

  return (
    <div
      role="checkboxgroup"
      className={`field-checkbox-group 
      field-group-images-${!!schema.enum_icons || hasImages()} 
      ${columns && columns !== 1 ? "field-layout-grid" : inline ? "field-layout-inline" : "field-layout-block"}`}
      id={id}
      style={columns && columns !== 1 ? gridTemplateStyles : {}}
    >
      {Array.isArray(enumOptions) &&
        enumOptions.map((option, i) => {
          const checked = enumOptionsIsSelected(option.value, value);

          const itemDisabled =
            Array.isArray(enumDisabled) &&
            enumDisabled.indexOf(option.value) !== -1;

          const disabledCls =
            disabled || itemDisabled || readonly ? "disabled" : "";

          const handleChange = (event) => {
            if (event.target.checked) {
              onChange(
                enumOptionsSelectValue(i, checkboxesValues, enumOptions)
              );
            } else {
              onChange(
                enumOptionsDeselectValue(i, checkboxesValues, enumOptions)
              );
            }
          };

          const isAnyCheckboxSelected = checkboxesValues.length > 0 && checkboxesValues.some((v) => v !== emptyValue);

          const checkbox = (
            <>
              <input
                type="checkbox"
                id={optionId(id, i)}
                name={id}
                required={required && !isAnyCheckboxSelected}
                value={String(i)}
                disabled={disabled || itemDisabled || readonly}
                checked={checked}
                autoFocus={autofocus && i === 0}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                aria-describedby={ariaDescribedByIds(id)}
        
                tabIndex={-1}
              />
              <div className="label-content">
                {!!schema.enum_icons?.[i] || !!option?.schema?.icon?.url ? (
                  <img
                    src={schema?.enum_icons?.[i] || option?.schema?.icon?.url}
                    alt={option?.schema?.icon?.alt_text || `${option.label}`}
                  />
                ) : (
                  <div className="checkmark"></div>
                )}

                <div>
                  <span dangerouslySetInnerHTML={{ __html: option.label }} />
                  {(!!schema.enum_descriptions?.[i] ||
                    option?.schema?.description) && (
                    <p className="label-description">
                      {schema.enum_descriptions?.[i] ||
                        option?.schema?.description}
                    </p>
                  )}
                </div>
              </div>
            </>
          );

          return (
            <label
              key={i}
              className={`${
                inline ? "checkbox-inline" : "checkbox"
              } selected-${checked} ${disabledCls}`}
              tabIndex={disabled ? -1 : 0}
              role="checkbox"
              htmlFor={optionId(id, i)}
              onKeyDown={(e) => {
                if (e.key === "Enter") handleChange();
              }}
            >
              {checkbox}
            </label>
          );
        })}
    </div>
  );
};

export default CheckboxWidget;
