import React, { useCallback } from "react";
import { getNumberPrecision } from "../../../lib/utils";

const NumberWidget = ({
  id,
  required,
  readonly,
  disabled,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  autofocus,
  schema,
  ...props
}) => {
  const {
    multipleOf,
    minimum,
    maximum,
    prefix,
    suffix,
    currency,
    currencySymbol = "$",
  } = schema;

  // Handle number precision based on multipleOf
  const precision = getNumberPrecision(multipleOf);

  const handleChange = useCallback(
    (event) => {
      const val = event.target.value;
      // Remove currency symbol, prefix, suffix, commas and any non-numeric chars except decimal point
      const cleanVal = val.replace(/[^\d.-]/g, "");

      // Convert to number
      let numValue = cleanVal === "" ? undefined : Number(cleanVal);

      // Apply constraints
      if (numValue !== undefined) {
        if (minimum !== undefined) {
          numValue = Math.max(minimum, numValue);
        }
        if (maximum !== undefined) {
          numValue = Math.min(maximum, numValue);
        }
        if (multipleOf) {
          numValue = Math.round(numValue / multipleOf) * multipleOf;
        }
      }

      onChange(numValue);
    },
    [onChange, minimum, maximum, multipleOf],
  );

  // Format display value
  const formatValue = (val) => {
    if (val === undefined || val === "") return "";

    if (currency) {
      // Use Intl.NumberFormat for currency formatting with proper thousand separators
      const formatted = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
      }).format(val);

      // Replace currency symbol if custom symbol provided
      return currencySymbol !== "$"
        ? formatted.replace("$", currencySymbol)
        : formatted;
    } else {
      // For non-currency numbers, still use NumberFormat but without currency styling
      let formatted = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
      }).format(val);

      if (prefix) {
        formatted = prefix + formatted;
      }
      if (suffix) {
        formatted = formatted + suffix;
      }

      return formatted;
    }
  };

  return (
    <div className="number-widget">
      <input
        id={id}
        type="text"
        required={required}
        disabled={disabled}
        readOnly={readonly}
        autoFocus={autofocus}
        value={formatValue(value)}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={onFocus}
        {...props}
      />
    </div>
  );
};

export default NumberWidget;
