import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import setupPublicMethods from "./lib/publicmethods";
import { useManyways, ManywaysProvider } from "./context/ManywaysContext";

// POLYFILL FOR SHARE API
try {
  const script = document.createElement("script");
  script.src = "https://unpkg.com/share-api-polyfill/dist/share-min.js";
  script.async = true;
  document.head.appendChild(script);
} catch (error) {
  console.log(error);
}

class ManywaysWrapper extends HTMLElement {
  connectedCallback() {
    const mountPoint = document.createElement("div");
    mountPoint.classList.add("manyways-wrapper");
    this.attachShadow({ mode: "open" }).appendChild(mountPoint);

    const urlParams = new URLSearchParams(window.location.search);
    const _slug = urlParams.get("slug");
    const _locale = urlParams.get("locale");
    const slug = _slug ? _slug : this.getAttribute("slug");
    const locale = _locale ? _locale : this.getAttribute("locale") || "en";
    const mode = this.getAttribute("mode") || "scroll";
    const customCss = this.getAttribute("customcss");
    const disablePublicMethods =
      this.hasAttribute("disable-public-methods") || false;

    const root = ReactDOM.createRoot(mountPoint);
    root.render(
      <App locale={locale} slug={slug} mode={mode} customCss={customCss} />,
    );

    if (!disablePublicMethods) {
      setupPublicMethods();
    }

    window.manyways.dispatcher.subscribe("journey/close", function () {
      const wrapper = document.querySelector("manyways-wrapper");
      if (wrapper) {
        const modal = wrapper.closest('div[id^="mw-modal-"]');
        if (modal) {
          modal.classList.remove("mw-modal--isOpen");
          modal.classList.add("mw-modal--isClosed");
          modal.setAttribute("data-mw-modal-state", "closed");
          modal.style.opacity = "0";

          setTimeout(() => {
            modal.style.visibility = "hidden";
          }, 300); // Match this to your CSS transition duration
        }
      }
    });
  }
}

customElements.define("manyways-wrapper", ManywaysWrapper);

// deal with modals
const sluggedElements = document.querySelectorAll("[mw-modal-open]");

if (sluggedElements.length > 0) {
  sluggedElements.forEach((el) => {
    const slug = el.getAttribute("mw-modal-open");

    // create a div for modal
    const modal = document.createElement("div");
    modal.setAttribute("id", `mw-modal-${slug}`);
    modal.setAttribute("data-mw-modal-state", "closed");
    modal.classList.add("mw-modal", "mw-modal--isClosed");

    // Only apply essential default styles
    const defaultStyles = {
      position: "fixed",
      zIndex: "1000001",
      left: "0",
      top: "0",
      width: "100vw",
      height: "100vh",
      visibility: "hidden",
      opacity: "0",
    };

    Object.entries(defaultStyles).forEach(([key, value]) => {
      modal.style[key] = value;
    });

    // add the custom element to the modal with the slug
    const mwWrapper = document.createElement("manyways-wrapper");
    mwWrapper.setAttribute("slug", slug);
    mwWrapper.setAttribute("showClose", true);
    modal.appendChild(mwWrapper);

    // add the modal to the body
    document.body.appendChild(modal);

    // Enhanced open modal event listener
    el.addEventListener("click", (e) => {
      e.preventDefault();
      modal.style.visibility = "visible";
      modal.style.opacity = "1";
      modal.classList.remove("mw-modal--isClosed");
      modal.classList.add("mw-modal--isOpen");
      modal.setAttribute("data-mw-modal-state", "open");
    });
  });
}

export { useManyways, ManywaysProvider, ManywaysWrapper };

const Manyways = {
  useManyways,
  ManywaysProvider,
  ManywaysWrapper,
};

export default Manyways;
