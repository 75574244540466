import { useManyways } from "../../context/ManywaysContext";
import { mergeNodetoLocaleNoSubNode } from "../../lib/utils";

const Header = () => {
  const { classNamePrefix, treeConfig, locale } = useManyways();

  const content = mergeNodetoLocaleNoSubNode(treeConfig?.run_mode, locale);

  return (
    <header>
      <div className={`${classNamePrefix}-container ${classNamePrefix}-header`}>
        {content?.logo && (
          <img
            className={`${classNamePrefix}-logo`}
            src={content?.logo?.url}
            alt="logo"
          />
        )}
        {content?.header && (
          <div
            className={`${classNamePrefix}-text-container`}
            dangerouslySetInnerHTML={{ __html: content?.header }}
          />
        )}
      </div>
    </header>
  );
};

export default Header;
