import { createContext, useContext, useEffect, useState } from "react";
import { mergeNodetoLocaleNoSubNode, slugify } from "../lib/utils";
import labels from "../lib/labels/index.js";
import NodeRenderer from "../components/NodeRenderer/NodeRenderer.js";
import Comments from "../components/Comments/Comments.js";
import axios from "axios";
import { setCookie, deleteCookie, getCookie } from "cookies-next";
import ManywaysFooter from "../components/Footer/ManywaysFooter.js";
import { IoCloseOutline } from "react-icons/io5";
import Login from "../components/Login/Login.js";
import Dashboard from "../components/Dashboard/Dashboard.js";

const ManywaysContext = createContext(null);

const injectAdditionalHeaderScripts = (revision) => {
  // handle additional header scripts
  if (!!revision?.run_mode?.additional_header_snippet) {
    console.log("additional header snippet found");
    document.head.insertAdjacentHTML(
      "beforeend",
      revision?.run_mode?.additional_header_snippet
    );
  }
};

const ManywaysProvider = ({
  children,
  slug,
  classNamePrefix = "mw",
  mode,
  locale,
}) => {
  let [nodes, setNodes] = useState([]);
  let [responseId, setResponseId] = useState(false);
  let [treeConfig, setTreeConfig] = useState({});
  let [currentNodeId, setCurrentNodeId] = useState(false);
  let [responses, setResponses] = useState([]);
  let [isLoading, setIsLoading] = useState(true);
  let [isCommenting, setIsCommenting] = useState(false);
  let [commentingNode, setCommentingNode] = useState(null);
  let [user, setUser] = useState(null);
  let [slugAndRevisionParams, setSlugAndRevisionParams] = useState(
    `${slug}/begin`
  );
  const [isStaging, setIsStaging] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInJourney, setIsInJourney] = useState(false);

  // console.log(responses, currentNodeId, 'RESPONSES')

  //Manyways API

  const requestOptions = {
    headers: {
      Authorization: `${user?.token}`,
    },
    timeout: 5000,
  };

  const manywaysApi = axios.create({
    baseURL: `https://mw-apiv2-prod.fly.dev`,
    timeout: 5000,
    ...requestOptions,
  });
  manywaysApi.interceptors.response.use(
    (response) => response,
    (error) => {
      // whatever you want to do with the error
      return {};
    }
  );

  //Check user logged in for comment

  let requiresAuth =
    !isStaging ||
    treeConfig?.run_mode?.authentication === "email" ||
    treeConfig?.run_mode?.authentication === "phone";

  if (!treeConfig?.run_mode?.authentication) {
    requiresAuth = false;
  }
  useEffect(() => {
    if (!isStaging) {
      return;
    }
    const userCookie = getCookie("MANYWAYS_USER");
    if (userCookie) {
      setUser(JSON.parse(userCookie));
    }
  }, []);

  //Login User for commenting
  const login = async ({ email, password }, callback = () => {}) => {
    let data = { user: { email, password } };

    try {
      const response = await manywaysApi.post("/login", data);

      if (response?.data) {
        const _user = {
          ...response.data?.status?.data?.user,
          token: response.headers?.authorization,
        };
        const exp = new Date() + 24 * 60 * 60;
        setCookie(
          "MANYWAYS_USER",
          {
            ..._user,
            exp,
          },
          { maxAge: 24 * 60 * 60 }
        );
        callback(_user);
        console.log("Login successful:", response.data);
        return response.data;
      } else {
        console.error("Login failed: No token received");
        return null;
      }
    } catch (error) {
      console.error("Login error:", error.message || error);
      return null;
    }
  };

  //Find current node from currentNodeId
  let currentNode =
    currentNodeId !== false ? nodes.find((n) => n.id === currentNodeId) : false;

  //Is journey previewing in wayfinder
  const isPreview = () => {
    // @TODO
    // if (window.location.search.includes("revision")) {
    //   let revisionId = window.location.search
    //     .split("revision=")[1]
    //     .split("&")[0];
    //   setSlugAndRevisionParams(`${slug}/begin?revision=${revisionId}`);
    // }

    return window.location.search.includes("preview");
  };

  useEffect(() => {
    const checkIsStaging = () => {
      const isRevision = window.location.search.includes("revision");
      setIsStaging(isRevision);
    };

    checkIsStaging();
  }, []);

  // modal styling
  useEffect(() => {
    console.log(treeConfig, "tree config");
    if (treeConfig?.run_mode?.modal_css) {
      let styleElement = document.getElementById("mw-modal-styles");
      if (!styleElement) {
        styleElement = document.createElement("style");
        styleElement.id = "mw-modal-styles";
        document.head.appendChild(styleElement);
      }
      styleElement.textContent = treeConfig?.run_mode?.modal_css;
    }
  }, [treeConfig]);

  const getRevisionId = () => {
    let revisonID =
      window?.location?.search?.split("revision=")?.[1]?.split("&")?.[0] || "";
    return revisonID ? `?revision=${revisonID}` : "";
  };

  const shouldContinue = () => {
    return window.location.search.includes("continue");
  };

  //Live Preview in Wayfinder
  const postMessageHandler = (ev) => {
    if (ev.data.type === "SCHEMA_UPDATED") {
      // console.log("from run", ev.data.revision);
      setTreeConfig(ev.data.revision);
      setCurrentNodeId(ev.data.node?.id);
      setNodes([ev.data.node]);
    }
  };

  useEffect(() => {
    if (!isPreview()) {
      return;
    }
    window.parent.postMessage({ type: "IFRAME_READY" }, "*");
    window.addEventListener("message", postMessageHandler);
    return () => {
      window.removeEventListener("message", postMessageHandler);
    };
  }, []);

  //Get Initial Data
  const getInitialData = async (
    props = {},
    ignoreContinue = false,
    ignorePreview = false
  ) => {
    const { callback = () => {}, callbackArgs = {} } = props;

    if (isPreview()) {
      setIsLoading(false);
      return;
    }

    if (shouldContinue() && !ignoreContinue) {
      const sessionId = window.location.search.split("continue=")[1];
      continueJourney(sessionId);
    } else {
      setIsLoading(true);
      await fetch(
        `https://mw-apiv2-prod.fly.dev/response_sessions/${slugAndRevisionParams}${getRevisionId()}`
      )
        .then((response) => response.json())
        .then((data) => {
          setNodes([data?.current_node]);
          setCurrentNodeId(data?.node_id);
          setResponseId(data?.id);
          setTreeConfig(data?.revision);
          injectAdditionalHeaderScripts(data?.revision);
          setIsLoading(false);
          callback({ data, nodes: [data?.current_node], callbackArgs });

          setTimeout(() => {
            // hack for tabs
            document
              .querySelectorAll(
                ".mw-node-find-by-form .field-radio-group label"
              )
              .forEach((el) => {
                el.addEventListener("click", function () {
                  if (!!el.closest(".is-current-node-true")) {
                    console.log("no response. should be forwarded");
                    return false;
                  } else {
                    console.log(
                      "response exists. should be restarted in queue"
                    );
                    window.manyways.restartInQueue([{ result: el.innerText }]);
                  }
                });
              });
          }, 400);
        });
    }
  };

  const goForward = async ({ formData }) => {
    if (isPreview()) {
      return;
    }
    if (!!isLoading) {
      console.log("is loading aborting go forward");
      return false;
    }
    setIsLoading(true);
    let theResponse = {
      node_id: currentNodeId,
      response: formData,
    };

    Object.keys(formData).forEach((key) => {
      try {
        window.umami.track(key + ": " + formData[key]);
      } catch (e) {
        console.log(e);
      }
    });

    await fetch(
      `https://mw-apiv2-prod.fly.dev/response_sessions/${responseId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(theResponse),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        try {
          console.log("tracking event");
          window.umami.track((props) => {
            console.log("tracking props", props);
            return {
              ...props,
              url: `/${slugify(data.title)}`,
              title: data.title,
            };
          });
        } catch (e) {
          console.log(e);
        }

        let final_json = data?.form_schema;
        try {
          final_json = data?.content;
        } catch (e) {
          console.log(e);
        }
        setNodes([...nodes, { ...data, form_schema: final_json }]);
        setResponses([
          ...responses,
          { node_id: currentNode?.id, ...theResponse },
        ]);
        setCurrentNodeId(data?.id);
        setIsLoading(false);
      });
  };

  const goBack = async function () {
    if (isPreview()) {
      return;
    }
    let currentNodeIndexInResponses = responses.findIndex(
      (r) => r.node_id === currentNodeId
    );
    currentNodeIndexInResponses =
      currentNodeIndexInResponses > -1
        ? currentNodeIndexInResponses
        : responses.length;
    let theLastResponse = responses[currentNodeIndexInResponses - 1];
    if (
      !!theLastResponse &&
      !!theLastResponse?.node_id &&
      responses?.[0].node_id === theLastResponse?.node_id
    ) {
      restart();
    } else if (!!theLastResponse && !!theLastResponse?.node_id) {
      let _nodes = nodes.filter((n, idx) => idx < nodes.length - 1);
      setNodes(_nodes);
      setCurrentNodeId(theLastResponse?.node_id);
    } else {
      console.log("cannot go back");
      console.log("the last response", theLastResponse);
    }
  };

  const continueJourney = async (sessionId) => {
    setIsLoading(true);
    await fetch(
      `https://mw-apiv2-prod.fly.dev/response_sessions/${sessionId}?render_response_nodes=true`
    )
      .then((response) => response.json())
      .then((data) => {
        const { current_node, responses, revision, rendered_nodes } = data;
        let _nodes = [...rendered_nodes, current_node].map((d) => {
          let final_json = d?.form_schema;
          try {
            if (!!d?.content) {
              final_json = d?.content;
            }
          } catch (e) {
            console.log(e);
          }
          return { ...d, form_schema: final_json };
        });
        setNodes(_nodes);
        setCurrentNodeId(current_node?.id);
        setResponseId(sessionId);
        setResponses(responses);
        setTreeConfig(revision);
        injectAdditionalHeaderScripts(revision);
        setIsLoading(false);
      });
  };

  const setQueueData = async ({ data, nodes, callbackArgs }) => {
    await fetch(`https://mw-apiv2-prod.fly.dev/response_sessions/${data?.id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ responses: callbackArgs }),
    })
      .then((response) => response.json())
      .then((d) => {
        continueJourney(data?.id);
      });
  };

  const restartInQueue = async (nodeData) => {
    setNodes([]);
    setResponses([]);
    setCurrentNodeId(false);
    setTreeConfig({});
    setIsLoading(true);
    await getInitialData({ callback: setQueueData, callbackArgs: nodeData });
  };

  const restart = async () => {
    setNodes([]);
    setResponses([]);
    setCurrentNodeId(false);
    setTreeConfig({});
    setIsLoading(true);
    await getInitialData({}, { ignoreContinue: true });
  };

  const shareJourney = () => {
    window.umami.track("Share");
    navigator
      .share({
        title: document.title,
        url: window.location.href,
      })
      .then((_) => console.log("Shared"))
      .catch((error) => console.log("Share failed", error));
  };

  const setUpUmami = () => {
    if (!treeConfig?.analytics_config?.umami_id) {
      return;
    }
    if (!!treeConfig.analytics_config.umami_id) {
      var el = document.createElement("script");
      el.setAttribute(
        "src",
        "https://umami-analytics-nine-xi.vercel.app/script.js"
      );
      el.setAttribute("data-website-id", treeConfig.analytics_config.umami_id);
      document.body.appendChild(el);
    }
  };

  useEffect(() => {
    window.manyways.restartInQueue = restartInQueue;
    window.manyways.restart = restart;
    getInitialData();
  }, [slug]);

  useEffect(() => {
    !!window?.manyways._garbage?.remove && window.manyways._garbage?.remove();
    window.manyways._garbage = window.manyways.dispatcher.subscribe(
      "graph/back",
      function (obj) {
        goBack();
      }
    );
  }, [currentNodeId]);

  useEffect(() => {
    setUpUmami();
  }, [treeConfig]);

  //Set scroll tracker variable
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const manywaysWrapper = document.querySelector("manyways-wrapper");

      if (manywaysWrapper) {
        manywaysWrapper.style.setProperty(
          "--scroll-position",
          `${scrollPosition}`
        );
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let getResponseByNodeID, journeyNodes, setLocale, copyLink;

  return (
    <ManywaysContext.Provider
      value={{
        nodes: nodes.map((n) => mergeNodetoLocaleNoSubNode(n, locale)),
        currentNodeId,
        currentNode,
        goBack,
        responses,
        goForward,
        getResponseByNodeID,
        treeConfig,
        journeyNodes,
        locale,
        labels: labels[locale],
        setLocale,
        shareJourney,
        copyLink,
        classNamePrefix,
        mode: treeConfig?.run_mode?.mode || mode,
        restart,
        isCommenting,
        setIsCommenting,
        commentingNode,
        setCommentingNode,
        manywaysApi,
        login,
        user,
        setUser,
        responseId,
        isStaging,
        continueJourney,
        isLoading,
        setIsLoading,
        isAuthenticated,
        setIsAuthenticated,
        isInJourney,
        setIsInJourney,
      }}
    >
      {children}
      {/* Apply custom css from wayfinder */}
      {treeConfig?.custom_css && (
        <style
          dangerouslySetInnerHTML={{ __html: treeConfig?.custom_css }}
        ></style>
      )}
      {requiresAuth ? (
        isAuthenticated && isInJourney ? (
          <>
            <NodeRenderer />
            <ManywaysFooter />
          </>
        ) : (
          <Login onLogin={() => setIsAuthenticated(true)} />
        )
      ) : (
        <>
          <NodeRenderer />
          <ManywaysFooter />
        </>
      )}
      {/* <NodeRenderer />
      <ManywaysFooter /> */}
    </ManywaysContext.Provider>
  );
};

const useManyways = () => useContext(ManywaysContext);

export { ManywaysProvider, useManyways };
