import { useManyways } from "../../context/ManywaysContext";

const NextAndBack = ({ currentNode = {}, className }) => {
  const {
    goBack,
    classNamePrefix,
    labels,
    restart,
    shareJourney,
    treeConfig,
    isLoading,
  } = useManyways();

  const { ui_variables } = currentNode;

  const allowSkip = treeConfig?.run_mode?.allow_skip;

  const hasNonNullFields = (node) => {
    const schema = node.form_schema;
    if (!schema || !schema.properties) return false;

    return Object.values(schema.properties).some(
      (prop) => prop.type !== "null",
    );
  };

  const isRequired =
    currentNode?.form_schema?.required &&
    currentNode?.form_schema?.required?.length > 0;

  const showSkipButton =
    allowSkip && hasNonNullFields(currentNode) && !isRequired;

  return (
    <div className={`${className} ${classNamePrefix}-next-and-back`}>
      {ui_variables?.end_node ? (
        <>
          <button
            className={`${classNamePrefix}-restart`}
            type="button"
            onClick={() => restart()}
          >
            {labels ? labels.restart : "Restart"}
          </button>
          <button
            className={`${classNamePrefix}-share`}
            type="button"
            onClick={() => shareJourney()}
          >
            {labels ? labels.share : "Share"}
          </button>
        </>
      ) : (
        <>
          {ui_variables?.custom_back_text !== "HIDE_BACK" && (
            <button
              type="button"
              className={`${classNamePrefix}-back`}
              onClick={(e) => {
                e.preventDefault();
                goBack();
              }}
            >
              {ui_variables?.custom_back_text
                ? ui_variables.custom_back_text
                : labels
                  ? labels.back
                  : "Back"}
            </button>
          )}
          <button className={`${classNamePrefix}-next`} type="submit">
            {isLoading
              ? "Loading..."
              : ui_variables?.custom_next_text
                ? ui_variables.custom_next_text
                : labels
                  ? labels.next
                  : "Next"}
          </button>

          {showSkipButton && (
            <button className={`${classNamePrefix}-skip`} type="submit">
              {"Skip"}
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default NextAndBack;
