import { useEffect, useState } from "react";
import Spreadsheet, { DataEditor } from "react-spreadsheet";
import DataEditorSelect from "./DataEditorSelect";
import SpreadsheetUploader from "./SheetUploader";

// EXAMPLE DATA
// {
// "withUploader":"Upload Documentation",
// "defaultView": "upload",
// "title": "",
// "fields": [{
//   "title": "Title"
// },
// {
//   "title": "Description"
// }],
// "data": [
// [{"value": ""},{"value": ""},],
// [{"value": ""},{"value": ""},],
// [{"value": ""},{"value": ""},],
// [{"value": ""},{"value": ""},],
// [{"value": ""},{"value": ""},],
// [{"value": ""},{"value": ""},],
// [{"value": ""},{"value": ""},],
// [{"value": ""},{"value": ""},],
// [{"value": ""},{"value": ""},],
// [{"value": ""},{"value": ""},],
// ]
// }

const ExcelSheetInput = ({ schema, onChange, formData, ...props }) => {
  const [properties, setProperties] = useState({});
  const [_data, setData] = useState([]);
  const [columnLabels, setColumnLabels] = useState([]);
  const [showUploader, setShowUploader] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const url = "https://g6-portal.vercel.app/api/upload";

  useEffect(() => {
    try {
      let properties = JSON.parse(schema.text);
      let data = properties.data;
      const columnLabels = properties.fields.map((f) => f.title);

      const hasDescriptions = properties.fields.some(
        (field) => field.description,
      );

      if (formData && Array.isArray(formData) && formData.length > 0) {
        data = formData.map((row) =>
          row.map((cell) => ({ value: cell || "" })),
        );
      } else {
        data = properties.data;
      }
      if (properties?.defaultView === "upload") {
        setShowUploader(true);
      }
      const subtitleRow = hasDescriptions
        ? properties.fields.map((field) => ({
            value: field.description || "",
            readOnly: true,
            className: "subtitle-cell",
          }))
        : null;

      const dataWithSubtitles = subtitleRow
        ? [
            subtitleRow,
            ...data.map((row) =>
              row.map((cell, colIndex) => ({
                value: cell.value || "",
                className: "data-cell",
                readOnly: false,
              })),
            ),
          ]
        : data.map((row) =>
            row.map((cell, colIndex) => ({
              value: cell.value || "",
              className: "data-cell",
              readOnly: false,
            })),
          );

      setProperties(properties);
      setData(dataWithSubtitles);
      setColumnLabels(columnLabels);
    } catch (e) {
      console.error(e);
    }
  }, [schema]);

  const handleFileUpload = (fileUrls) => {
    onChange(fileUrls);
    setUploadedFiles(fileUrls);
    setShowUploader(false);
  };

  const handleUploadError = (error) => {
    setUploadError(error.message || "Error uploading file");
  };

  const handleDeleteUploads = () => {
    setUploadedFiles([]);
    onChange(null);
  };

  const handleDataEditor = (props) => {
    const colIndex = props.column;
    const options = properties.fields?.[colIndex]?.options;

    if (options) {
      return <DataEditorSelect {...props} options={options} />;
    }

    return <DataEditor {...props} />;
  };

  const handleDataViewer = (props) => {
    return <span>{props?.cell?.value}</span>;
  };

  const handleChange = (newData) => {
    const hasDescriptions = properties.fields?.some(
      (field) => field.description,
    );

    let updatedData;
    if (hasDescriptions) {
      const subtitleRow = _data[0];
      updatedData = [subtitleRow, ...newData.slice(1)];
      setData(updatedData);
    } else {
      updatedData = newData;
      setData(updatedData);
    }

    const formattedData = hasDescriptions
      ? updatedData.slice(1).map((row) => row.map((cell) => cell.value))
      : updatedData.map((row) => row.map((cell) => cell.value));

    if (onChange) {
      onChange(formattedData);
    }
  };

  const addNewRow = () => {
    const newRow = properties.fields.map(() => ({
      value: "",
      className: "data-cell",
      readOnly: false,
    }));

    const hasDescriptions = properties.fields?.some(
      (field) => field.description,
    );

    if (hasDescriptions) {
      setData([..._data.slice(0, -1), newRow, _data[_data.length - 1]]);
    } else {
      setData([..._data, newRow]);
    }
  };

  return (
    _data?.length > 0 && (
      <div className={`spreadsheet-container ${schema?.customClassName || ""}`}>
        <h3 style={{ marginBottom: "1rem" }}>{properties.title}</h3>

        {!showUploader ? (
          <>
            <div className="spreadsheet">
              <Spreadsheet
                columnLabels={columnLabels}
                data={_data}
                onChange={handleChange}
                DataEditor={handleDataEditor}
                DataViewer={handleDataViewer}
              />
              <div className="spreadsheet-actions">
                <button
                  type="button"
                  className="add-row-button"
                  onClick={addNewRow}
                >
                  Add Row
                </button>
              </div>
            </div>
            {properties.withUploader && (
              <div className="spreadsheet-upload-toggle">
                <button
                  type="button"
                  className="spreadsheet-uploader"
                  onClick={() => setShowUploader(true)}
                  url={url}
                >
                  {typeof properties.withUploader === "string"
                    ? properties.withUploader
                    : "Upload instead"}
                </button>
                {uploadedFiles.length > 0 && (
                  <div className="uploaded-files-info">
                    <span>{uploadedFiles.length} file(s) uploaded</span>
                    <button
                      type="button"
                      className="delete-uploads"
                      onClick={handleDeleteUploads}
                      aria-label="Delete uploaded files"
                    >
                      ✕
                    </button>
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <div className="spreadsheet-upload-section">
            {uploadError && <div className="upload-error">{uploadError}</div>}
            <SpreadsheetUploader
              onFileUpload={handleFileUpload}
              onError={handleUploadError}
              url={url}
            />
            <button
              type="button"
              className="spreadsheet-manual-entry"
              onClick={() => {
                setShowUploader(false);
                setUploadError(null);
              }}
            >
              Spreadsheet Entry
            </button>
          </div>
        )}
      </div>
    )
  );
};
export default ExcelSheetInput;
