import { useRef, useState } from "react";
import { useManyways } from "../../../context/ManywaysContext";

const TabbedContent = ({ schema }) => {
  const { classNamePrefix } = useManyways();
  const { tabs, orientation } = schema;

  const [currentTab, setCurrentTab] = useState(0);
  const tabRefs = useRef([]);

  const handleTabClick = (index, event) => {
    event.preventDefault();
    setCurrentTab(index);
    tabRefs.current[index].focus();
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "ArrowDown" && currentTab < tabs.length - 1) {
      setCurrentTab((prevTab) => prevTab + 1);
      tabRefs.current[index].focus();
    } else if (event.key === "ArrowUp" && currentTab > 0) {
      setCurrentTab((prevTab) => prevTab - 1);
      tabRefs.current[index].focus();
    }
  };

  return (
    <div className={`${classNamePrefix}-tabs-container`}>
      {orientation === "stacked" && tabs?.length > 1 ? (
        <ul className={`${classNamePrefix}-tabs-stacked`}>
          {tabs.map((tab, index) => (
            <li
              tabIndex={index === currentTab ? 0 : -1}
              ref={(ref) => (tabRefs.current[index] = ref)}
              role="tab"
              aria-label={""}
              aria-selected={index === currentTab ? "true" : "false"}
              onKeyDown={(event) => handleKeyDown(event, index)}
              key={index}
            >
              <a
                className={`${classNamePrefix}-tab-button ${classNamePrefix}-selected-${index === currentTab}`}
                onClick={(event) => handleTabClick(index, event)}
              >
                {tab?.name}
                <span className="completed">
                  <i className="fa-regular fa-circle-check fa-lg"></i> 
                </span>
              </a>
              {currentTab === index && (
                <div
                  className={`${classNamePrefix}-tab-content ${classNamePrefix}-selected-${currentTab === index}`}
                  style={{
                    maxHeight: currentTab === index ? "100%" : "0",
                    overflow: "hidden",
                    animation:
                      "grow 350ms cubic-bezier(0.4, 0, 1, 1) forwards 100ms",
                  }}
                >
                  <div
                    className="text-container"
                    dangerouslySetInnerHTML={{ __html: tab?.content }}
                  />
                </div>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <div className={`${classNamePrefix}-tabs-${orientation}`}>
          <ul>
            {tabs.map((tab, index) => (
              <li
                tabIndex={index === currentTab ? 0 : -1}
                ref={(ref) => (tabRefs.current[index] = ref)}
                role="tab"
                aria-label={""}
                aria-selected={index === currentTab ? "true" : "false"}
                onKeyDown={(event) => handleKeyDown(event, index)}
                key={`title-${index}`}
              >
                <a
                  className={`${classNamePrefix}-tab-button ${classNamePrefix}-selected-${index === currentTab}`}
                  onClick={(event) => handleTabClick(index, event)}
                >
                  {tab?.name}
                  <span className="completed">
                    <i className="fa-regular fa-circle-check fa-lg"></i> 
                  </span>
                </a>
              </li>
            ))}
          </ul>
          {tabs.map(
            (tab, index) =>
              currentTab === index && (
                <div
                key={`content-${index}`}
                  className={`${classNamePrefix}-tab-content ${classNamePrefix}-selected-${currentTab === index}`}
                  style={{
                    maxHeight: currentTab === index ? "100%" : "0",
                    overflow: "hidden",
                    animation:
                      "grow 350ms cubic-bezier(0.4, 0, 1, 1) forwards 100ms",
                  }}
                >
                  <div
                    className="text-container"
                    dangerouslySetInnerHTML={{ __html: tab?.content }}
                  />
                </div>
              )
          )}
        </div>
      )}
    </div>
  );
};

export default TabbedContent;
