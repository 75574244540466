import Form from "@rjsf/core";
import { useManyways } from "../../context/ManywaysContext";
import {
  isFormWithOneChoiceFieldOnly,
  slugify,
  transformErrors,
} from "../../lib/utils";
import validator from "@rjsf/validator-ajv8";
import NextAndBack from "../NextAndBack/NextAndBack";
import widgets from "../Widgets";
import fields from "../Fields";
import Footer from "../Footer/Footer";
import { forwardRef } from "react";

const SingleNode = forwardRef(({ currentNode, onHover }, ref) => {
  const {
    nodes,
    goBack,
    responses,
    classNamePrefix,
    currentNodeId,
    goForward,
    mode,
    treeConfig,
    locale,
    commentingNode,
    setCommentingNode,
    isCommenting,
  } = useManyways();

  // UI VARIABLES
  let UIVariables = currentNode?.ui_variables || {};
  let { translations = {} } = currentNode;
  let globalUIVariables = treeConfig?.run_mode || {};
  let impliedUIVariables = {
    ...globalUIVariables,
    ...UIVariables,
  };

  let { background_image, node_layout, foreground_image, full_screen } =
    impliedUIVariables;

  const singleChoiceField = isFormWithOneChoiceFieldOnly(
    currentNode?.form_schema,
    currentNode?.ui_schema,
  );

  let theResponse = responses
    .slice()
    .reverse()
    .find((r) => r.node_id === currentNode?.id);
  let currentNodeIndex = nodes.findIndex((n) => n.id === currentNode?.id);
  let hasNextNode = !!nodes[currentNodeIndex + 1];

  const handleMouseEnter = () => setCommentingNode(currentNode.id);

  return (
    <div
      key={currentNode?.id}
      ref={ref}
      className={`${classNamePrefix}-node
        is-current-node-${currentNodeId === currentNode?.id}
        has-response-${!!theResponse}
        layout-${node_layout || "center"}
        is-full-screen-${!!full_screen}
        has-background-${!!background_image?.url}
        has-foreground-${!!foreground_image}
        is-first-node-${currentNodeIndex === 0}
        ${classNamePrefix}-node-${slugify(currentNode?.title)}
      `}
      id={`${classNamePrefix}-node-${currentNode?.id}`}
      style={{
        ...(!!background_image?.url && {
          backgroundImage: `url(${background_image?.url})`,
        }),
      }}
      onMouseEnter={handleMouseEnter}
    >
      <div className={`${classNamePrefix}-container`}>
        {!!foreground_image?.url && (
          <div className={`${classNamePrefix}-foreground-image-wrapper`}>
            <img
              alt={currentNode?.title}
              className={`${classNamePrefix}-foreground-image`}
              src={foreground_image?.url}
            />
          </div>
        )}
        <Form
          disabled={!!hasNextNode}
          formData={theResponse?.response || {}}
          className={`${classNamePrefix}-form ${classNamePrefix}-node-${slugify(
            currentNode?.title,
          )}-form
            has-response-${!!theResponse}
          `}
          fields={fields}
          widgets={widgets}
          onChange={(e) => {
            if (!!singleChoiceField) {
              goForward(e);
            }
          }}
          transformErrors={(e) => transformErrors(e, translations, locale)}
          key={currentNode?.id || 1123456789}
          onSubmit={goForward}
          schema={currentNode?.form_schema || {}}
          validator={validator}
          uiSchema={!!currentNode?.ui_schema ? currentNode?.ui_schema : {}}
        >
          <NextAndBack
            currentNode={currentNode}
            className={`singleChoiceField-${singleChoiceField} next-and-back-hidden-${
              mode === "scroll" && !!hasNextNode
            }`}
          />
        </Form>
      </div>
      {commentingNode === currentNode.id && isCommenting && (
        <div className="comment-overlay-active"></div>
      )}
    </div>
  );
});

export default SingleNode;
