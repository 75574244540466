import { useManyways } from "../../context/ManywaysContext";
import { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";

const CommentThread = ({ thread }) => {
  const { manywaysApi, commentingNode } = useManyways();
  const [comments, setComments] = useState([]);
  const [reply, setReply] = useState(false);
  const [formData, setFormData] = useState([]);
  const [isClosed, setIsClosed] = useState(false);

  const colors = [
    "#e9d5ff",
    "#bbf7d0",
    "#fbcfe8",
    "#fef08a",
    "#bfdbfe",
    "#c7d2fe",
    "#fee2e2",
    "#fdcfe8",
  ];

  const generateColor = (id) => {
    const index = parseInt(id, 10) % colors.length;
    return colors[index];
  };

  const getInitials = (firstName, lastName) => {
    const firstInitial = firstName.charAt(0).toUpperCase();
    const lastInitial = lastName.charAt(0).toUpperCase();
    return `${firstInitial}${lastInitial}`;
  };

  const formatDate = (dateString) => {
    const date = parseISO(dateString);
    return format(date, "MMM d, yyyy HH:mm");
  };

  useEffect(() => {
    if (!thread?.id) {
      return;
    }
    const fetchComments = async () => {
      const _comments = await manywaysApi.get(`/node_threads/${thread.id}`);
      setComments(_comments?.data?.node_comments);
      console.log(_comments);
    };

    fetchComments();
  }, [thread?.id]);

  const schema = {
    properties: {
      comment: { type: "string", title: "Reply", minLength: 1 },
    },
  };

  const uiSchema = {
    comment: {
      "ui:widget": "textarea",
    },
  };

  const handleSubmitReply = async (e) => {
    const res = await manywaysApi.post(
      `/node_threads/${thread?.id}/node_comments`,
      {
        node_comment: { comment: formData?.comment },
      }
    );

    console.log(res, "response");

    if (res.status === 201) {
      const updatedComments = await manywaysApi.get(
        `/node_threads/${thread.id}`
      );
      setComments(updatedComments?.data?.node_comments);
      setFormData({ comment: "" });
    }
  };

  const handleCloseThread = async () => {
    const res = await manywaysApi.patch(`/node_threads/${thread.id}`, {
      closed: true,
    });
    setIsClosed(true);
  };

  return (
    comments?.length > 0 && (
      <div className="comment-thread">
        <div className="thread-header">
          <h3>{thread.title}</h3>
          {!isClosed ||
            (!thread.closed && (
              <div className="thread-options">
                <button className="btn" onClick={() => setReply(!reply)}>
                  Reply
                </button>

                <button className="btn" onClick={handleCloseThread}>
                  Resolve
                </button>
              </div>
            ))}
        </div>

        <div className="comments">
          {comments.map((comment, index) => (
            <div
              key={comment.id}
              className={`comment ${index === 0 ? "first-comment" : "sub-comment"}`}
            >
              {console.log(comments, thread, "thread")}
              <div
                style={{ backgroundColor: generateColor(comment.user_id) }}
                className={`initials`}
              >
                {getInitials(comment.user.first_name, comment.user.last_name)}
              </div>
              <div className="info">
                <div className="flex items-center">
                  <span className="user-name">{comment.user.first_name}</span>
                  <span className="date">{formatDate(comment.created_at)}</span>
                </div>
                <p>{comment.comment}</p>
              </div>
            </div>
          ))}
        </div>
        {reply && (
          <Form
            schema={schema}
            uiSchema={uiSchema}
            onSubmit={handleSubmitReply}
            validator={validator}
            formData={formData}
            onChange={({ formData }) => setFormData(formData)}
          >
            <div className="comment-footer">
              <button type="submit" className="submit-reply">
                Submit
              </button>
            </div>
          </Form>
        )}
      </div>
    )
  );
};

export default CommentThread;
