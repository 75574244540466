import en from "./en.json";
import fr from "./fr.json";

const labels = {
  en,
  en_AS: en,
  en_AU: en,
  en_BE: en,
  en_BZ: en,
  en_BW: en,
  en_CA: en,
  en_GU: en,
  en_HK: en,
  en_IN: en,
  en_IE: en,
  en_IL: en,
  en_JM: en,
  en_MT: en,
  en_MH: en,
  en_MU: en,
  en_NA: en,
  en_NZ: en,
  en_MP: en,
  en_PK: en,
  en_PH: en,
  en_SG: en,
  en_ZA: en,
  en_TT: en,
  en_UM: en,
  en_VI: en,
  en_GB: en,
  en_US: en,
  en_ZW: en,
  fr,
  fr_BE: fr,
  fr_BJ: fr,
  fr_BF: fr,
  fr_BI: fr,
  fr_CM: fr,
  fr_CA: fr,
  fr_CF: fr,
  fr_TD: fr,
  fr_KM: fr,
  fr_CG: fr,
  fr_CD: fr,
  fr_CI: fr,
  fr_DJ: fr,
  fr_GQ: fr,
  fr_FR: fr,
  fr_GA: fr,
  fr_GP: fr,
  fr_GN: fr,
  fr_LU: fr,
  fr_MG: fr,
  fr_ML: fr,
  fr_MQ: fr,
  fr_MC: fr,
  fr_NE: fr,
  fr_RW: fr,
  fr_RE: fr,
  fr_BL: fr,
  fr_MF: fr,
  fr_SN: fr,
  fr_CH: fr,
  fr_TG: fr,
};

export default labels;
