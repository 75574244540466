import { useState } from "react";
import { useManyways } from "../../../context/ManywaysContext";
import { IoMdArrowForward, IoMdArrowBack } from "react-icons/io";

const Slideshow = ({ schema, ...props }) => {
  const { classNamePrefix } = useManyways();
  const { slides } = schema;

  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  const slideStyles = {
    height: schema?.slideHeight || "100%",
  };

  return (
    <div className={`${classNamePrefix}-slideshow-wrapper`}>
      <div className="slide" style={slideStyles}>
        {slides[currentIndex]?.content && (
          <div
            className="text-container"
            dangerouslySetInnerHTML={{
              __html: slides[currentIndex]?.content,
            }}
          />
        )}
        {slides[currentIndex]?.image && (
          <img
            src={slides[currentIndex].image?.url}
            alt={slides[currentIndex].image?.alt_text}
          />
        )}
      </div>

      <div className="controls">
        <div className="dots">
          {slides.map((_, index) => (
            <button
              type="button"
              key={index}
              className={`dot ${index === currentIndex ? "active" : ""}`}
              onClick={() => goToSlide(index)}
              aria-label={`Go to slide ${index + 1}`}
            ></button>
          ))}
        </div>
        <div className="arrows">
          <button
            type="button"
            className="arrow left-arrow"
            onClick={goToPrevious}
            aria-label="Previous slide"
          >
            <span className="">Previous Slide</span>
            {/* <IoMdArrowBack /> */}
          </button>
          <button
            type="button"
            className="arrow right-arrow"
            onClick={goToNext}
            aria-label="Next slide"
          >
            <span className=""> Next Slide</span>
            {/* <IoMdArrowForward /> */}

          </button>
        </div>
      </div>
    </div>
  );
};

export default Slideshow;
