import { useManyways } from "../../../context/ManywaysContext";

const PDFResponseButton = ({ schema }) => {
  const { responseId } = useManyways();
  let theUrl = "https://wayfinder.manyways.io/ccc-pdf";
  return (
    <a
      href={`${theUrl}?responseId=${responseId}`}
      className="button pdf-response-button"
      target="_blank"
      style={{
        marginTop: "20px",
        display: "inline-block",
      }}
    >
      View PDF &rarr;
    </a>
  );
};

export default PDFResponseButton;
