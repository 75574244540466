import React from "react";
import Select from "react-select";

const DataEditorSelect = ({ cell, onChange, options, row, column }) => {
  const fieldId = `cell-${row}-${column}`;

  if (!options || options.length === 0) {
    return (
      <input
        type="text"
        id={fieldId}
        name={fieldId}
        value={cell.value || ""}
        onChange={(e) => onChange({ ...cell, value: e.target.value })}
        autoFocus
        className="custom-data-editor-input"
      />
    );
  }

  const selectOptions = options.map((option) => ({
    value: option,
    label: option,
  }));

  const currentValue = cell.value
    ? { value: cell.value, label: cell.value }
    : null;

  return (
    <Select
      value={currentValue}
      options={selectOptions}
      onChange={(selected) =>
        onChange({ ...cell, value: selected?.value || "" })
      }
      autoFocus
      className="custom-data-editor-select"
      classNamePrefix="select-mw"
      menuPortalTarget={document.body}
      aria-hidden="true"
      ariaLiveMessages={{
        guidance: () => "",
        onChange: () => "",
        onFocus: () => "",
        onFilter: () => "",
      }}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      }}
    />
  );
};

export default DataEditorSelect;
