import { useState } from "react";
import { useManyways } from "../../context/ManywaysContext";
import axios from "axios";
import { format, parseISO } from "date-fns";

const Login = ({ onLogin }) => {
  const [step, setStep] = useState("INPUT");
  const [contactInfo, setContactInfo] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [error, setError] = useState("");
  const {
    treeConfig,
    continueJourney,
    setIsAuthenticated,
    setIsInJourney,
    isInJourney,
    isAuthenticated,
  } = useManyways();
  const [continueIds, setContinueIds] = useState([]);
  const authType = treeConfig?.run_mode?.authentication;

  const API_BASE_URL = "https://g6-portal.vercel.app/api";

  const handleRequestCode = async (e) => {
    e.preventDefault();
    setError("");

    try {
      //????
      const response = await axios.post(`${API_BASE_URL}/login`, {
        type: authType,
        [authType]: contactInfo,
      });

      console.log(response);

      if (response.status === 200) {
        setStep("VERIFY");
      } else {
        setError(response.data.message || "Failed to send verification code");
      }
    } catch (err) {
      setError(
        err.response?.data?.message || "Failed to send verification code",
      );
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const response = await axios.post(`${API_BASE_URL}/verify`, {
        type: authType,
        [authType]: contactInfo,
        code: verificationCode,
      });

      if (response.status === 200 && response?.data?.journeys?.length) {
        setContinueIds(response.data.journeys);
        setStep("SELECT JOURNEYS");
        setIsAuthenticated(true);
      } else if (response.status === 200 && !response?.data?.journeys?.length) {
        setError("Forbidden.");
      } else {
        setError(response.data.message || "Invalid verification code");
      }
    } catch (err) {
      setError(err.response?.data?.message || "Verification failed");
    }
  };

  const handleContinueJourney = (journey) => {
    setIsInJourney(true);
    const newUrl = `?slug=${journey.slug}&continue=${journey.continueId}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
  };

  const handleShareJourney = (journeyId) => {
    window.manyways.share({ type: "click" });
  };

  const formatDate = (dateString) => {
    try {
      const date = parseISO(dateString);
      return format(date, "MMM d, yyyy");
    } catch (error) {
      console.error("Date parsing error:", error);
      return dateString;
    }
  };

  return (
    <div className="mw-login-screen">
      {step === "INPUT" ? (
        <form onSubmit={handleRequestCode}>
          <p>
            {authType === "email"
              ? "Enter your email"
              : "Enter your phone number"}
          </p>
          {error && <div className="mw-login-error">{error}</div>}
          <div className="mw-login-field">
            <input
              type={authType === "email" ? "email" : "tel"}
              value={contactInfo}
              onChange={(e) => setContactInfo(e.target.value)}
              placeholder={
                authType === "email"
                  ? "Your email address"
                  : "Your phone number"
              }
              required
            />
          </div>
          <button type="submit">Get Verification Code</button>
          <div className="mw-logo">
            <img src="/logo-manyways.png" alt="Manyways Logo" />
          </div>
        </form>
      ) : step === "VERIFY" ? (
        <form onSubmit={handleVerifyCode}>
          <p>Enter Verification Code</p>
          <p className="mw-login-subtitle">
            We've sent a code to {contactInfo}. Please check your spam folders
            in case you don't see it in your inbox.
          </p>
          {error && <div className="mw-login-error">{error}</div>}
          <div className="mw-login-field">
            <input
              type="text"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              placeholder="Enter code"
              required
            />
          </div>
          <button type="submit">Verify Code</button>
          <button
            type="button"
            className="mw-login-back"
            onClick={() => setStep("INPUT")}
          >
            Back
          </button>
          <div className="mw-logo">
            <img src="/logo-manyways.png" alt="Manyways Logo" />
          </div>
        </form>
      ) : (
        <div>
          <div className="mw-dashboard">
            <div className="mw-dashboard-container">
              <div className="mw-dashboard-header">
                <h1>Please select to continue</h1>
              </div>

              <div className="mw-dashboard-journeys">
                {continueIds.length === 0 ? (
                  <div className="mw-dashboard-no-journeys">
                    <p>You haven't started any journeys yet.</p>
                  </div>
                ) : (
                  <div className="mw-journey-selection-list">
                    {continueIds.map((journey) => (
                      <div
                        key={journey.continueId}
                        className="mw-journey-selection-item"
                      >
                        <div className="journey-info">
                          <h3>{journey.title}</h3>
                          <span className="status">{journey.slug}</span>
                          <span className="date">
                            {formatDate(journey.created_at)}
                          </span>
                        </div>
                        <div className="journey-actions">
                          <button
                            className="continue"
                            onClick={() => handleContinueJourney(journey)}
                          >
                            Continue Journey
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
