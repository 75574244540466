import { IoMdClose } from "react-icons/io";
import { HiOutlineArchive } from "react-icons/hi";

import { useManyways } from "../../context/ManywaysContext";
import CommentLogin from "./Comments.Login";
import { useEffect, useState } from "react";
import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import CommentThread from "./Comments.Thread";
import { FaRegComments } from "react-icons/fa";

const Comments = () => {
  const {
    isCommenting,
    setIsCommenting,
    commentingNode,
    user,
    manywaysApi,
    nodes,
  } = useManyways();
  const [threads, setThreads] = useState([]);
  const [filteredThreads, setFilteredThreads] = useState([]);
  const [showResolved, setShowResolved] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({ title: "", comment: "" });

  const schema = {
    properties: {
      title: { type: "string", title: "Subject", minLength: 1 },
      comment: { type: "string", title: "Comment", minLength: 1 },
    },
  };

  const uiSchema = {
    title: {
      "ui:placeholder": "Enter thread subject",
    },
    comment: {
      "ui:widget": "textarea",
      "ui:placeholder": "Type your comment",
    },
  };

  const handleNewThread = async () => {
    const res = await manywaysApi.post(
      `/nodes/${commentingNode}/node_threads`,
      {
        node_thread: formData,
      }
    );

    if (res.status === 201) {
      fetchThreads();
      setFormData({ title: "", comment: "" });
    }
  };

  const fetchThreads = async () => {
    console.log("fetching threads");
    const _comments = await manywaysApi.get(
      `/nodes/${commentingNode}/node_threads`
    );
    console.log(_comments?.data, "received");
    if (_comments?.data) {
      setThreads(_comments?.data);
    }
  };

  useEffect(() => {
    if (!isCommenting || !commentingNode) {
      return;
    }
    fetchThreads();
  }, [isCommenting, commentingNode, user]);

  useEffect(() => {
    if (showResolved) {
      setFilteredThreads(threads);
    } else setFilteredThreads(threads.filter((thread) => !thread.closed));
  }, [showResolved, threads]);

  return (
    <>
      <button className="comment-btn" onClick={() => setIsCommenting(true)}>
        <span className="sr-only">comment</span>
        <FaRegComments />
      </button>
      {isCommenting && (
        <div className="comment-panel">
          <div className="header">
            {user && nodes ? (
              commentingNode ? (
                <h3>
                  {" "}
                  {nodes.find((node) => commentingNode === node.id)?.title}
                </h3>
              ) : (
                <h3> Comments</h3>
              )
            ) : (
              <h3>Login</h3>
            )}

            <div>
              <button
                className="toggle-resolved"
                onClick={() => setShowResolved(!showResolved)}
                style={{ backgroundColor: showResolved ? "#eee" : "#fff" }}
              >
                <HiOutlineArchive />
              </button>
              <button
                className="toggle-btn"
                onClick={() => setIsCommenting(false)}
              >
                <IoMdClose />
              </button>
            </div>
          </div>
          <div className="comment-body">
            {user && commentingNode ? (
              <>
                <div className="threads">
                  {filteredThreads &&
                    filteredThreads
                      .slice()
                      .reverse()
                      .map((thread, threadIdx) => (
                        <CommentThread key={threadIdx} thread={thread} />
                      ))}
                </div>
                <div className="comment-add">
                  {showForm ? (
                    <Form
                      schema={schema}
                      uiSchema={uiSchema}
                      onSubmit={handleNewThread}
                      validator={validator}
                      formData={formData}
                      onChange={({ formData }) => setFormData(formData)}
                    >
                      <div className="form-actions" type="button">
                        <button onClick={() => setShowForm(false)}>
                          Cancel
                        </button>
                        <button type="submit">Submit</button>
                      </div>
                    </Form>
                  ) : (
                    <div className="form-actions" type="button">
                      <button onClick={() => setIsCommenting(false)}>
                        {" "}
                        Close
                      </button>

                      <button onClick={() => setShowForm(true)}>
                        {" "}
                        Start a thread{" "}
                      </button>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <CommentLogin />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Comments;
