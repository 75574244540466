import { useEffect, useState } from "react";
import Select from "react-select";
import { useManyways } from "../../../context/ManywaysContext";

const SelectWidget = ({
  value,
  onChange,
  disabled,
  options,
  schema,
  ...props
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { classNamePrefix } = useManyways();

  useEffect(() => {
    const _theOptions =
      !!options?.enumOptions && !!options?.enumOptions?.length > 0
        ? options?.enumOptions
        : [];
    if (_theOptions?.length === 1) {
      onChange(_theOptions[0].value);
    }
  }, []);

  // close on escape
  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.keyCode === 27) {
        setMenuIsOpen(false);
      }
    };

    const shiftTabKeyListener = (event) => {
      if (event.keyCode === 9 && event.shiftKey) {
        // 9 is the keycode for Tab key
        setMenuIsOpen(false);
      }
    };
    document.addEventListener("keydown", handleEscKey);
    document.addEventListener("keydown", shiftTabKeyListener);

    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  const theOptions =
    !!options?.enumOptions && !!options?.enumOptions?.length > 0
      ? options?.enumOptions
      : [];

  let theValue = theOptions.find((o) => o.value === value);
  if (!theValue) {
    theValue = false;
  }
  return (
    <div
      className={`is-searchable-${!!schema?.isSearchable} ${classNamePrefix}-select `}
    >
      {!!menuIsOpen && (
        <>
          <div
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              zIndex: "1",
              opacity: "0",
            }}
            onClick={(e) => {
              setMenuIsOpen(false);
            }}
          ></div>
          <div
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              width: "80px",
              height: "100%",
              zIndex: "20",
              opacity: "0",
              cursor: "pointer",
            }}
            onClick={(e) => {
              setMenuIsOpen(false);
            }}
          ></div>
        </>
      )}
      <Select
        onChange={(v) => {
          onChange(v.value);
          setMenuIsOpen(false);
        }}
        onMenuOpen={() => {}}
        onFocus={() => {
          setMenuIsOpen(true);
        }}
        onDropdownClose={() => {}}
        blurInputOnSelect={true}
        onBlur={() => {}}
        menuIsOpen={menuIsOpen}
        isDisabled={disabled}
        isSearchable={schema?.isSearchable}
        value={theValue}
        placeholder={props.placeholder}
        options={theOptions}
        classNamePrefix="select-mw"
      />
    </div>
  );
};

export default SelectWidget;
