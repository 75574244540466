import CheckboxWidget from "./CheckboxWidget/CheckboxWidget";
import CurrencyWidget from "./CurrencyWidget/CurrencyWidget";
import RadioWidget from "./RadioWidget/RadioWidget";
import SelectWidget from "./SelectWidget/SelectWidget";

const widgets = {
  RadioWidget,
  SelectWidget,
  CheckboxesWidget: CheckboxWidget,
  currency: CurrencyWidget,
};

export default widgets;
