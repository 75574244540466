import styles from "./styles/main.scss";

import { ManywaysProvider } from "./context/ManywaysContext";

function App({ locale, slug, mode, customCss }) {

  const stylesToString = customCss
    ? `${styles}${customCss}`
    : `${styles}`;


  return (
    <ManywaysProvider slug={slug || 'sdk-test-journey'} locale={locale} mode={mode}>
      <style dangerouslySetInnerHTML={{ __html: stylesToString }}></style>
    </ManywaysProvider>
  );
}

export default App;
